.Footer {
    width: 100%;
    box-sizing: border-box;
    margin-top: min(60px, 10vh);
}

.Footer .copyright {
    font-size: 1.4rem;
}

.Footer .footerContents {
    padding-top: 20px;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 60px;
}

.Footer .footerFlex {
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}
.Footer .footerFlex > * {
    margin: 5px;
}

.Footer .linkGroup {
    display: flex;
    flex-direction: column;
}
.Footer .linkGroup a {
    margin: 5px;
}

.Footer a {
    color: var(--theme-text-color);
    text-decoration: none;
}
.Footer a:hover {
    text-decoration: underline;
}

.Footer .footerSettings {
    display: flex;
    width: 100%;
    padding: 0 35px;
    box-sizing: border-box;
    align-items: center;
    flex-wrap: wrap;
}
.Footer .footerSettings button {
    background: none;
    width: 35px;
    height: 35px;
    padding: 0;
}
.Footer .footerSettings button > svg {
    width: 100%;
    height: 100%;
}
.Footer .footerSettings button:hover {
    color: var(--theme-text-color);
}