.Navbar {
    display: flex;
    font-size: 2.0rem;
    padding: 10px 10px;
    font-weight: lighter;
    background-color: var(--navbar-color);
    border-bottom: 2px solid var(--navbar-sub-border-color);
    min-height: 4.0rem;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: sticky;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 6;
    user-select: none;
}

.Navbar > * {
    margin: 0 15px;
    text-decoration: none;
}
.Navbar > *:first-child {
    display: flex;
    align-items: center;
}
.Navbar > *:last-child {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
.Navbar .profileMenuButton.pfp {
    height: 3.0rem;
    width: 3.0rem;
    border-radius: 50%;
    background-color: var(--navbar-color-hover);
    overflow: hidden;
    padding: 0;
}
.Navbar .profileMenuButton.pfp:hover,.Navbar .profileMenuButton.pfp.selected {
    box-shadow: 0 0 0 5px var(--navbar-color-hover);
}
.Navbar .profileMenuButton.pfp.selected:hover {
    box-shadow: 0 0 0 5px var(--navbar-color-selected-hover);
}
.Navbar .pfp img {
    width: 100%;
}
.Navbar .hamburgerMenuButton {
    height: 2.8rem;
    width: 2.8rem;
    display: none;
}
.Navbar button {
    background: none;
    transition: none;
    margin: 0 10px;
    padding: 5px;
    font-size: inherit;
}

.Navbar > * > span, .hamburgerLinks > * {
    margin: 0 10px;
    padding: 5px;
    cursor: pointer;
    text-decoration: none;
    border-radius: 5px;
}
.Navbar > * > span:hover,.Navbar > * > button:hover,
.Navbar > * > *.selected, .hamburgerLinks > *:hover,
.Navbar .mainLink:hover {
    background-color: var(--navbar-color-hover);
}
.Navbar > * > span.selected:hover,.Navbar > * > button.selected:hover {
    background-color: var(--navbar-color-selected-hover);
}

.ProfileMenu,.HamburgerMenu {
    position: fixed;
    width: 250px;
    max-width: 60vw;
    background-color: var(--background-color);
    border-bottom: 3px solid var(--navbar-sub-border-color);
    padding: 20px;
    visibility: hidden;
    transition: all 0.5s ease;
    text-align: left;
    display: flex;
    flex-direction: column;
    z-index: 5;
}
.ProfileMenu.open,.HamburgerMenu.open {
    visibility: visible;
    transform: translateY(0);
}
.Navbar a,.ProfileMenu > a,.HamburgerMenu > a {
    text-decoration: none;
    font-weight: normal;
    color: inherit;
    padding: 5px;
    border-radius: 5px;
}
.ProfileMenu > a:hover, .HamburgerMenu > a:hover {
    background-color: var(--navbar-color-hover);
}
.ProfileMenu .authButtonContainer {
    text-align: center;
    display: flex;
    justify-content: center;
}

.ProfileMenu {
    right: 0;
    transform: translateY(-100%);
    border-left: 3px solid var(--navbar-sub-border-color);
    border-radius: 0 0 0 15px;
}
.HamburgerMenu {
    left: 0;
    transform: translateY(-100%);
    border-right: 3px solid var(--navbar-sub-border-color);
    border-radius: 0 0 15px 0;
}

.mainLink {
    font-weight: normal;
    display: flex;
    align-items: center;
}
.mainLink img {
    height: 2.8rem;
    margin-right: 5px;
}

.Navbar .upgradeLink {
    background-color: var(--theme-accent-color);
    color: white;
    padding-left: 10px;
    padding-right: 10px;
}
.Navbar .upgradeLink:hover {
    background-color: var(--button-color);
}
.HamburgerMenu .upgradeLink {
    /* font-weight: bold; */
    color: var(--theme-accent-color);
}

.hamburgerMenuButton .HamburgerMenuIcon #hamburger > line {
    transition: all 0.5s;
    transform: none;
    transform-origin: center;
}
.hamburgerMenuButton.selected .HamburgerMenuIcon #hamburger > line:nth-child(1) {
    transform: rotate(45deg) translateY(35%);
}
.hamburgerMenuButton.selected .HamburgerMenuIcon #hamburger > line:nth-child(3) {
    transform: rotate(-45deg) translateY(-35%);
}
.hamburgerMenuButton.selected .HamburgerMenuIcon #hamburger > line:nth-child(2) {
    transform: rotate(45deg);
}

.NavbarBanner {
    box-sizing: border-box;
    width: 100%;
    background-color: var(--theme-accent-color);
    color: white;
    text-align: center;
    padding: 3px;
}

.Navbar .darkmodeButton {
    padding: 3px;
    height: calc(2.0rem + 4px);
}
.Navbar .darkmodeButton svg {
    height: calc(2.0rem + 4px);
    padding: 0;
}

@media screen and (max-width: 768px) {
    .Navbar {
        font-size: 1.8rem;
    }
    .Navbar > * {
        margin: 0 5px;
    }
    .Navbar button {
        margin: 0 5px;
    }
    .Navbar .hamburgerMenuButton,.Navbar .darkmodeButton {
        margin: 0 2px;
    }
    .Navbar .hamburgerMenuButton {
        display: inline-block;
        height: 2.0rem;
        width: 2.0rem;
    }
    .Navbar .hamburgerLinks {
        display: none;
    }
    .Navbar > * > span, .hamburgerLinks > * {
        margin: 0;
        font-size: 80%;
    }
}