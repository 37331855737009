.PopupNotificationContainer {
    display: flex;
    flex-direction: column-reverse;
    position: fixed;
    top: min(100px, 10vh);
    right: min(100px, 10vw);
    z-index: 1000;
}

@keyframes popup {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
.PopupNotificationContainer .PopupNotification {
    margin: 10px;
    width: 300px;
    max-width: 40vw;
    min-height: 30px;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
    background-color: var(--background-color);
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    right: min(10px, 10vw);
    opacity: 0;
    transition: all 0.5s ease-in-out;
    visibility: hidden;
    z-index: 1000;
}
.PopupNotificationContainer .PopupNotification.popping, .PopupAlertContainer .PopupAlert.popping {
    animation: popup;
    animation-duration: 0.5s;
}
.PopupNotificationContainer .PopupNotification.visible {
    opacity: 1;
    visibility: visible;
}
.PopupNotificationContainer .PopupNotification .icon {
    margin-right: 10px;
    padding: 5px;
}
.PopupNotificationContainer .PopupNotification .icon,.PopupNotificationContainer .PopupNotification .close {
    height: 20px;
    width: 20px;
    overflow: visible;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow: visible;
}
.PopupNotificationContainer .PopupNotification .icon svg,.PopupNotificationContainer .PopupNotification .close svg {
    overflow: visible;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.PopupNotificationContainer .PopupNotification .icon svg {
    height: 20px;
    width: 20px;
}
.PopupNotificationContainer .PopupNotification .close svg {
    height: 15px;
    width: 15px;
}
.PopupNotificationContainer .PopupNotification .close {
    margin-left: 10px;
    cursor: pointer;
    padding: 5px;
}

.PopupAlertContainer .PopupAlert {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    max-width: 50vw;
    overflow-wrap: anywhere;
    margin: auto;
    background-color: var(--neutral-gray);
    z-index: 1001;
}
.PopupAlertContainer .PopupAlert > * {
    margin: 10px;
}
.PopupAlertContainer .PopupAlert .actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.PopupAlertContainer .PopupAlert .actions > * {
    margin: 10px;
}

@media screen and (max-width: 768px) {
    .PopupAlertContainer .PopupAlert {
        max-width: 80vw;
    }
    .PopupAlertContainer .PopupAlert .actions {
        font-size: 75%;
    }
}