:root {
  --background-color: #fff;
  --navbar-color: #fff;
  --neutral-gray: #E2E2E2;
  --navbar-color-hover: #bbb;
  --navbar-color-selected-hover: #99a;
  --navbar-sub-border-color: #aaa;
  --text-color: #124;
  --actions-box-color: #001;
  --big-actions-box-color: #C2E2F2;
  --action-button-color: #555;
  --action-button-color-hover: #777;
  --action-button-color-active: #999;
  --big-action-button-color: var(--big-actions-box-color);
  --big-action-button-color-hover: var(--helper-button-color-hover);
  --check-color: #0C0;
  --check-color-hover: #0E0;
  --x-color: #B00;
  --x-color-hover: #F00;
  --warning-color: #C80;
  --warning-color-hover: #F80;
  --info-color: #08C;
  --info-color-hover: #0AF;
  --tooltip-color: #424292;
  --tooltip-text-color: #fff;
  --button-color: #A2D2E2;
  --button-color-hover: #C2E2F2;
  --red-button-color: #E24242;
  --red-button-color-hover: #F26262;
  --theme-text-color: #367;
  --note-text-color: #556;
  --minor-text-color: #999;
  --faded-text-color: #ddd;
  /* --theme-accent-color: #02A2C2; */
  --theme-accent-color: #29A4B5;
  --selection-color: rgba(2, 162, 194, 0.3);
  --helper-color: #f0fafa;
  --helper-button-color: #578;
  --helper-button-color-hover: #79a;
  --helper-button-color-selected: #245;
  --focus-scrollbar-color: #ccc;
}

.themeContainer.dark {
  --background-color: #000;
  --neutral-gray: #1d1d1d;
  --navbar-color: #1d1d1d;
  --navbar-color-hover: #444;
  --navbar-color-selected-hover: #666;
  --navbar-sub-border-color: #555;
  --text-color: #def;
  --helper-color: #101f1f;
  --faded-text-color: #444;
  --note-text-color: #99a;
  --big-actions-box-color: var(--button-color);
  --big-action-button-color: var(--button-color);
  --big-action-button-color-hover: var(--helper-button-color);
  --theme-text-color: #8bc;
  --button-color: #1d4d5d;
  --button-color-hover: #3d5d6d;
  --focus-scrollbar-color: #333;
  --actions-box-color: #222;
}