.AuthButton{
    transition: background-color .218s;
    user-select: none;
    cursor: pointer;
    /* font-family: 'Roboto', arial, sans-serif; */
    font-size: 16px;
    letter-spacing: 0.25px;
    text-align: center;
    white-space: nowrap;
    background-color: var(--background-color);
    border: 1px solid #dadce0;
    width: 174px;
    height: 40px;
    vertical-align: middle;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: nowrap;
    font-weight: normal;
    border-radius: 20px;
    padding: 0 12px;
}
.AuthButton:hover {
    background-color: var(--button-color-hover);
}
.AuthButton:active {
    background-color: var(--button-color);
}

.AuthLogo {
    height: 18px;
    width: 18px;
}

.themeContainer.dark .AuthButton {
    color: #e8eaed;
    background-color: #202124;
    border: none
}
.themeContainer.dark .AuthButton:hover {
    background-color: var(--button-color-hover);
}
.themeContainer.dark .AuthButton:active {
    background-color: var(--button-color);
}